@import "./variables";

// SIDER TOGGLER

.desktop-aside-toggler {
  background: lighten($themePrimaryColor, 10);
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.5);
  min-width: $togglerSize;
  height: $togglerSize;
  min-height: $togglerSize;
  width: $togglerSize;
  padding: 0;
  float: right;
  position: relative;
  top: 2.5rem;
  right: -1rem;
  z-index: $zIndex;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.5);
  border-radius: 100%;
  // clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  &:after {
    content: "";
    min-width: 10px;
    background: url("../assets/img/f-icons/icon-arrow-dark.svg") center center no-repeat;
    transform: rotate(-180deg);
    background-size: 10px;
    height: 100%;
    width: 100%;
    margin: auto;

    transition: $transition;
  }
  &.open {
    right: -1rem;
    &:after {
      transform: rotate(360deg);
    }
  }
}

.f-sider-outer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zIndex * 9;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.5);
}
.f-sider-items-whiteLabeled {
  padding: 0px 30px !important;
}
.f-sider-outer-whiteLabeled {
  background-color: #443065 !important;
}
aside {
  padding: 1rem 0;
  top: 0;
  bottom: 0;
  max-width: $siderCollapsedWidth;
  min-width: $siderCollapsedWidth - 20px;
  width: 100%;
  height: 100vh;

  transition: $transition;
  .f-logo {
    display: flex;
    margin: 1.75rem 0 2rem;
    max-width: $logoSize + 100px;
    min-width: 45px;
    overflow: hidden;
    padding: 0 $spacer;
    img {
      width: 100%;
      // min-width: $logoSize;
      min-width: 175px;
      margin-bottom: 1rem;
      overflow: hidden;
      transition: $transition;
    }
  }
  .f-sider-item {
    display: block;
    width: 100%;
    background: none;
    padding: 0.75rem 0;
    .f-item-link {
      display: flex;
      align-items: center;
      text-decoration: none;
      transition: $transition;
      font-size: 0.9rem;
      line-height: 1.5;
      color: white;
      text-decoration: none;
      transition: $transition;
      .f-item-icon {
        margin-right: 0.5rem;
        line-height: 0;
        vertical-align: -2px;
        font-size: 1.5rem;
        min-width: 25px;
        margin-right: 0.5rem;
        transition: transform $transition;
        color: white;
        svg {
          fill: white;
          font-size: 1.5rem;
        }
        &:last-child {
          margin-right: 0;
          transform-origin: 7px 4px;
        }
      }
      .f-item-text {
        display: none;
        transition: $transition;
      }
    }
    &:hover {
      background: $themePrimaryColor;
    }
    &.active {
      background: $themePrimaryColor;
    }
  }
  .f-sider-item-whiteLabeled {
    display: block;
    width: 100%;
    height: 40px;
    background: none;
    padding: 10px 0;
    margin-bottom: 10px;
    .f-item-link {
      display: flex;
      align-items: center;
      text-decoration: none;
      transition: $transition;
      font-size: 0.9rem;
      line-height: 1.5;
      // color: white;
      text-decoration: none;
      transition: $transition;
      .f-item-icon {
        margin-right: 0.5rem;
        line-height: 0;
        vertical-align: -2px;
        font-size: 1.5rem;
        min-width: 25px;
        margin-left: 20px;
        transition: transform $transition;
        // color: white;
        svg {
          fill: white;
          font-size: 1.5rem;
        }
        &:last-child {
          margin-right: 0;
          transform-origin: 7px 4px;
        }
      }
      .f-item-text {
        color: #fff;
        transition: $transition;
      }
    }
    &:hover {
      // background: $themePrimaryColor;
      background: #fff;
      border-radius: 12px;
      .f-item-text {
        color: black;
        transition: $transition;
      }
    }
    &.active {
      // background: $themePrimaryColor;
      background: #fff;
      border-radius: 12px;
      .f-item-text {
        color: black;
        transition: $transition;
      }
    }
  }
  &.sider-collapsed {
    padding: 0.5rem;
    .f-logo {
      margin-top: 2.15rem;
      max-width: 40px;
      min-width: 40px;
      padding: 0;
      img {
        min-width: 100px;
        padding-left: 0.8rem;
      }
    }
    .f-sider-item {
      padding: 0.5rem 0.75rem;
      .f-item-link {
        .f-item-icon {
          margin-right: 0;
        }
      }
    }
    .has-child {
      &.f-sider-item &.f-sider-item-whiteLabeled {
        position: relative;
        > .f-item-link {
          // pointer-events: none;
        }
        .f-item-link {
          .f-item-icon {
            &:last-child {
              position: absolute;
              right: -0.45rem;
              transform: rotate(-90deg);
            }
          }
        }
        .f-sider-submenu-item {
          display: none;
          position: absolute;
          z-index: $zIndex * 2;
          .f-sider-item {
            padding: 0.5rem 0.75rem;
            min-width: 200px;
            .f-item-link {
              .f-item-text {
                display: inline;
                white-space: nowrap;
              }
            }
          }
        }
        // &:hover,
        &.submenu-open {
          .f-item-link {
            .f-item-icon {
              &:last-child {
                transform: rotate(90deg);
              }
            }
          }
          .f-sider-submenu-item {
            background: #212b36;
            transform: translateX(57px);
            top: 0;
            left: 0;
            display: inline;
          }
        }
      }
    }
  }
  &.sider-expanded {
    background-color: #272b30;
    max-width: $siderExpandedWidth;
    min-width: $siderExpandedWidth;
    width: 100%;
    .f-sider-item {
      border-bottom: 1px solid $borderColor;
      padding: 0.75rem $spacer;
      &:last-child {
        border-bottom: 0 !important;
      }
      .f-item-link {
        display: flex;
        .f-item-text {
          display: inline;
          width: 100%;
        }
      }
      &.has-child {
        transition: all $transition;

        &.f-sider-item &.f-sider-item-whiteLabeled {
          background: none;
          border-bottom: 1px solid $borderColor;
          > .f-item-link {
            // pointer-events: none;
          }
          &:hover {
            cursor: pointer;
          }
        }
        .f-sider-submenu-item {
          display: none;
          border-bottom: 0 solid $themePrimaryColor;
          .f-sider-item {
            padding: 0.75rem $spacer * 2;
            border: 0;
            width: calc(100% + 3rem);
            margin-left: -1.5rem;
          }
          &:first-child {
            border-top: 0 solid $themePrimaryColor;
          }
          &:last-child {
            border: 0;
          }
        }
      }
      &.submenu-open {
        &.f-sider-item &.f-sider-item-whiteLabeled {
          // padding-bottom: 0;
          .f-item-link {
            .f-item-icon {
              &:last-child {
                transform: rotate(90deg);
              }
            }
          }
        }
        .f-sider-submenu-item {
          margin: 0.5rem 0;
          display: block;
          // background: lighten($baseColor, 20) !important;
        }
      }
    }
  }
}
